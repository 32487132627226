











































import Vue from 'vue'
import {Routes} from '@/router/index.ts';


const RouteData:Array<any> = [];
Routes.forEach((route) => {
  if(route.meta?.needsAuth)
    RouteData.push({name: route.name, path: route.path})
});

console.log(RouteData);

export default Vue.extend({
  name: 'ToolBar',

  data: () => ({
    items: RouteData,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing e'
  }),
})
