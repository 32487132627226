import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const Routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Dashboard.vue'),
    meta:{ needsAuth: true }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Services.vue'),
    meta:{ needsAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Login.vue'),
    meta:{ needsAuth: false , hideNavigation: true }
  },
  { path: '/', redirect: { name: 'Dashboard' } },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/404.vue'),
    meta:{ needsAuth: false , hideNavigation: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: Routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.needsAuth)){
    if(localStorage.getItem('jwt') == null){
      next({name: 'Login', params: { next: to.fullPath }})
    } else {
      next()
    }
  } else { //logon / register
    if(localStorage.getItem('jwt') != null){ //u haz token gtfo
      next({name: 'Dashboard'})
    } else {
      next()
    }
  }
})

export {Routes};

export default router
