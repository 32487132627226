


















import Vue from 'vue';
import ToolBar from './components/ToolBar.vue';

export default Vue.extend({
  name: 'App',

  components: {
    ToolBar,
  },

  data: () => ({
    //
  }),
});
